import product from "./product";
import Head from "next/head";

const MAP_TYPENAME_COMPONENT = {
  ComponentMicrodataProduct: product,
};

export const PageMicrodata = ({ microdata = [], locale }) => {
  const generatedMicrodata = microdata.map(({ __typename, ...data }) => {
    return MAP_TYPENAME_COMPONENT[__typename].getModel(data);
  });

  if (generatedMicrodata.length === 0) {
    return null;
  }

  const insertJSONLD = () => {
    return {
      __html: JSON.stringify(
        {
          "@context": "https://schema.org",
          "@graph": generatedMicrodata,
        },
        null,
        2
      ),
    };
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        key="page_microdata"
        dangerouslySetInnerHTML={insertJSONLD()}
      ></script>
    </Head>
  );
};

export default PageMicrodata;
