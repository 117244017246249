import getImageObject from "./image-object";

export const getModel = ({
  name,
  image,
  description,
  brand,
  review,
  offer,
}) => {
  return {
    "@type": "Product",
    name,
    description,
    image: getImageObject(image),
    brand: {
      "@type": "Brand",
      name: brand,
    },
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: review.rating,
        bestRating: "5",
        worstRating: "1",
      },
      author: {
        "@type": "Person",
        name: review.author,
      },
    },
    offers: {
      "@type": "Offer",
      url: offer.url,
      price: offer.price,
      priceValidUntil:
        offer.priceValidUntil && new Date(offer.priceValidUntil).toISOString(),
      priceCurrency: offer.currency,
      itemCondition: "http://schema.org/NewCondition",
    },
  };
};

export default {
  getModel,
};
